<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <div class="leaves m-2 p-3 pb-5 p-md-5">
    <h2 class="font-weight-bold">Your Leave Calendar</h2>

    <v-calendar v-if="!$browserDetect.isIE" is-expanded ref="calendar" locale="en-US" :columns="$screens({ default: 1, md: 2, lg: 3 })" :step="1" :attributes='calendarAttributes'></v-calendar>

    <h4 class="pt-4 pb-1 font-weight-normal">Current | Upcoming Leaves</h4>
    <b-table @row-clicked="item=>toggleDataDetailsRow($set, item)" :tbody-tr-class="item=>isBlockLeave(item) ? 'leave-block' : 'leave-row-clickable'" stacked="md" striped borderless :items="leaves.current" :fields="leaves.fields" class="leave-table" >
      <template v-slot:cell(leaveId)="data">
        <div class="color-code d-inline-block text-center" :style="{backgroundColor: leaveColorMap[data.item.leaveId].hex}"><i v-if="!isBlockLeave(data.item)" class="icon leave-icon d-none d-md-inline" :class="data.detailsShowing ? 'icon-arrow-down3' : 'icon-arrow-right3'"></i></div> {{ data.item.leaveId }}
      </template>

      <template v-slot:row-details="data">
        <div v-for="scheduleDetail in data.item.scheduleDetails" class="schedule-detail py-1">
          {{ scheduleDetail.period }} {{showHours(scheduleDetail)}} Export Suppressed: {{scheduleDetail.exportSuppressed}}
        </div>
      </template>
    </b-table>

    <h4 class="pt-4 pb-1 font-weight-normal">Previous Leaves</h4>
    <b-table stacked="md" striped borderless :items="leaves.closed" :fields="leaves.fields" class="leave-table">
      <template v-slot:cell(leaveId)="data">
        <div class="color-code d-none d-md-inline-block" style="background-color: silver"></div> {{ data.item.leaveId }}
      </template>
    </b-table>
  </div>
</template>

<script>
  import ApiProperties from '../../../src/main/resources/application'
  import dateMixin from '../mixins/dateMixin.js';
  import leaveMixin from '../mixins/leaveMixin.js';

  const COLORS = [
    {name: 'blue', hex: '#3182ce'},
    {name: 'yellow', hex: '#d69e2e'},
    {name: 'teal', hex: '#319795'},
    {name: 'pink', hex: '#d53f8c'},
    {name: 'indigo', hex: '#5a67d8'},
    {name: 'orange', hex: '#dd6b20'},
    {name: 'green', hex: '#38a169'},
    {name: 'red', hex: '#e53e3e'},
    {name: 'purple', hex: '#805ad5'},
  ];

  /**
   * COMPONENT: V-Calendar
   *
   * NOTES:
   * V-Calendar is still in Beta for 1.0, but most of this should work for what we need. Some of the docs do NOT
   * seem to work the way they're written, but for the most part, we should be able to manipulate it.
   *
   * inside calendarAttributes, we can use the following objects: bar{},dot{},highlight{}.
   * -- They have an impact on how the calendar highlights the date or date range, but cannot control the styles as
   * written in the documentation.
   *
   * inside above objects: color: '' - colors have to be names (i.e. red, blue, green, purple, etc..) No other
   * attributes seem to work inside this object.
   *
   */
  export default {
    name: 'leaves',
    data: function() {
      return {
        calendar: {},
        calendarAttributes: [
          {
            key: 'today',
            dot:{
              color: "gray"
            },
            dates: new Date()
          }
        ],

        leaves: {
          fields: [
            { key: "leaveId", label: "Leave #"},
            { key: "leavePeriod", label: "Leave Period"},
            { key: "returnDate", label: 'Return'},
            { key: "totalWeeks", label: 'Weeks'},
            { key: "leaveType", label: 'Types'},
            { key: "status", label: 'Status'},
            // { key: "accommodationRequested", label: 'Accommodation Requested'},
            // { key: "accommodationGranted", label: 'Accommodation Granted'},
          ],
          current: [],
          closed: []
        },

        leaveColorMap: [],
        errors: []
      }
    },
    mixins: [dateMixin, leaveMixin],

    methods: {
      toggleDataDetailsRow(setter, item) {
        if (!this.isBlockLeave(item)) {
          setter(item, '_showDetails', !item._showDetails)
        }
      },

      getHexColorByType(leave, colorType) {
        let color = this.leaveColorMap[leave.leaveId][colorType];

        return VC_HEX_DARK_COLORS[color];
      },

      showHours(scheduleDetail) {
        return (scheduleDetail.hours) ? `(${parseInt(scheduleDetail.hours)} hours)` : '';
      },

      drawLeaveDetails(leave, colorMap) {
        for (let i=0; i<leave.scheduleDetails.length; i++) {
          let key = `${leave.leaveId}-${i}`;
          let detail = leave.scheduleDetails[i];

          this.calendarAttributes.push({
            key: key,
            highlight: {
              color: colorMap[leave.leaveId].name
            },
            dates: {
              start: this.formatDateFromString(detail.from),
              end: this.formatDate(detail.to ? detail.to : detail.from)
            }
          })
        }
      },

      getLeavesByPortalUser: function() {
        this.$http.get(ApiProperties.api.leavesByPortalUser).then(response => {
          this.initializeLeaveObjectAndColorMap(response.data);
        }).catch(e => {
          this.errors.push(e);
        })
      },

      initializeLeaveObjectAndColorMap: function(allLeaves) {
        let colorIndexCounter = 0;

        for (let i=0; i<allLeaves.length; i++) {
          let leave = allLeaves[i];

          // add display attributes to the leave
          leave.leavePeriod = this.getLeavePeriodDateRangeString(leave.startDate, leave.endDate);
          leave.returnDate = (leave.rflDate != null) ? this.formatDate(leave.rflDate) : '';
          leave.scheduleDetails = this.flattenScheduleDetailsFromDetailsAndDateRanges(leave);

          //setup the current and closed leaves
          if (leave.status !== 'Closed') {
            this.leaves.current.push(leave);

            // define the color map
            this.leaveColorMap[leave.leaveId] = COLORS[colorIndexCounter];

            //draw the details
            this.drawLeaveDetails(leave, this.leaveColorMap);

            colorIndexCounter++;
          } else {
            this.leaves.closed.push(leave);
          }

        }
      }
    },

    created: function() {
      this.getLeavesByPortalUser();
    },

    mounted: function() {
      // Get reference to the calendar component
      this.calendar = this.$refs.calendar;
    }
  }
</script>

<style lang="scss">
  @import "../scss/variables";
  @import '../../node_modules/bootstrap/scss/functions';
  @import '../../node_modules/bootstrap/scss/variables';
  @import '../../node_modules/bootstrap/scss/mixins';

  .leaves {
    .b-table {
      &.table.b-table-stacked-sm {
        tbody {
          tr {
            margin-bottom: 20px;
          }

          tr > [data-label] {
            grid-template-columns: 50% auto !important;
          }
        }
      }
    }

    table.leave-table {
      tbody {
        td {
          white-space: nowrap;
        }

        .color-code {
          vertical-align: middle;
          background-color: silver;
          content: '';
          display: inline-block;
          width: 18px;
          height: 18px;
          margin-right: 3px;
          border-radius: 50%;
        }

        @include media-breakpoint-up(md) {
          td:first-child {
            width:100px;
          }

          .color-code {
            width: 32px;
            height: 32px;
            margin-right:10px;
          }
        }

        td {
          vertical-align: middle;
        }
      }
    }

    i.leave-icon {
      color: #fff;
      position:relative;
      top: 6px;
      font-size: .8rem;
    }

    @include media-breakpoint-up(md) {
      table.leave-table {
        .b-table-has-details, .b-table-details {
          background-color: #fbfafa;
        }

        .b-table-has-details {
          td {
            padding-bottom: 0;
          }
        }

        .b-table-details {
          td {
            padding-top:0;
            padding-bottom: 15px;
          }
        }

        tr.leave-row-clickable:hover { cursor: pointer; }

        tbody {
          .schedule-detail {
            margin-left: 135px;
            font-size: .8rem;

            .color-code {
              width:25px;
              height:25px;
              margin-right: 5px;
            }
          }
        }
      }
    }
  }
</style>